.rc-pagination {
  display: flex;
  gap: 0.5rem;
}

.rc-pagination-item {
  display: flex;
  justify-content: center;
  width: 3rem;
  padding: 0.5rem ;
  border-radius: 12px;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}

.rc-pagination-item-active {
  background-color: #f5f5f5;
  color: black;
}